<template>
  <ARow style="background-color: white; padding: 0">
    <ACol class="logo" :xs="24" :sm="24" :lg="12" :xl="3"  style="padding-left: 24px;">
        <RouterLink to="/" class="logo__link"><img class="logo__img" src="/assets/images/oncolung-logo.svg" alt="OncoLung"><span class="logo__text">OncoLung</span></RouterLink>
    </ACol>
    <ACol :xs="24" :sm="24" :lg="12" :xl="13">
      <AMenu mode="horizontal" :selected-keys="selectedKeys">
        <AMenuItem key="variants">
          <RouterLink to="/variants">Генетические варианты</RouterLink>
        </AMenuItem>
        <AMenuItem key="samples">
          <RouterLink to="/samples">Образцы</RouterLink>
        </AMenuItem>
        <AMenuItem key="contacts">
          <RouterLink to="/contacts">Контакты</RouterLink>
        </AMenuItem>
      </AMenu>
    </ACol>
    <ACol :xs="24" :sm="24" :lg="24"  :xl="8" style="padding-left: 24px; padding-right: 24px;">
      <AInputSearch
          v-if="path !== 'home'"
          v-model:value="query"
          placeholder="Например: “c.2156G>C”, “EGFR” или “p.Gly12Asp”"
          size="large"
          enter-button="Поиск"
          @search="onSearch"
          style="width: 100%; padding-top: 10px"
          class="search-header"
      />
    </ACol>
  </ARow>
</template>

<style>
  .ant-menu {
    border-bottom: none;
    font-size: 16px;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item {
    height: 64px;
    top: 0;
    display: flex;
    align-items: center;
  }

  .logo {
      display: flex;
      align-items: center;
      padding-right: 16px;
      border-bottom: 2px solid transparent;
  }

  .logo__link {
      color: #1f1f1f;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;

      display: flex;
      align-items: center;
  }

  .logo__link:hover, .logo__link:active {
      color: #1f1f1f;
      text-decoration: none;
      cursor: pointer;
  }

  .logo__text {
      margin-left: 8px;
      font-weight: 500;
  }
  @media (max-width: 576px) {
    .ant-menu-title-content {
      font-size: 12px;
    }
    .ant-menu-light.ant-menu-horizontal > .ant-menu-item {
      height: auto;
    }
  }
  @media (max-width: 992px) {
    .logo {
      justify-content: center;
      padding-top: 24px;
    }
    .ant-menu {
      justify-content: center;
    }
  }
  @media (max-width: 1200px) {
    .search-header {
      margin-bottom: 12px;
    }
  }




</style>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref, watch, computed} from "vue";

const route = useRoute();
const router = useRouter();

const replacements = {
  'variantDetails': 'variants',
  'variants': 'variants',
  'about': 'about',
  'contacts': 'contacts',
  'samples': 'samples',
  'samplesByVariant': 'samples',
}

const selectedKeys = ref([replacements[route.name]]);
const query = ref(route.query.q)
const path = computed(() =>route.name)

watch(
    () => route.name,
    async () => {
      selectedKeys.value = [replacements[route.name]]
      query.value = route.query.q
      return true;
    }
);

const onSearch = (query) => {
  router.push({ path: "/variants", query: {q: query}})
  return true
}

</script>