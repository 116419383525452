<template>
  <div class="footer">
      <div style="width: 200px;">        
      </div>
      <div> 
        {{ year }} © OncoLung       
      </div>
      <div class="footer__update-date">   
          обновлено: {{ updateDate }}
      </div>      
  </div>
</template>
<style>
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__update-date {
  width: 200px; 
  color: #a4a4a4;
  text-align: right;
}

@media screen and (max-width: 576px) { 
  .footer {
    flex-direction: column;
  }
  .footer > div {
    margin-bottom: 4px;
  }
  .footer__update-date {
    text-align: center;
  }
}

</style>
<script setup>
  import {ref, computed} from 'vue'
  import { metadata } from "@/http-common";

  const year = ref((new Date()).getFullYear())
 
  const updateDate = computed(() => {
    let date = new Date(metadata.sampleStats.lastCreated);
    const year = date.getFullYear();

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }

    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }

    date = year + "/" + month + "/" + day;
    return date;
  });

</script>