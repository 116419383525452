<template>
  <div :title="tooltip" style="white-space: nowrap">
    <span :style="main ? 'font-weight: bold' : ''">{{ label }}</span>
  </div>

</template>
<script setup>
import {effects} from "@/constants";
import {toRefs} from "vue";

const props = defineProps(['consequence', 'main']);
const {main} = toRefs(props);

let line = '';

if (props.consequence) {
  if (typeof props.consequence === 'string') {
    let effect = effects[props.consequence ];
      if (!effect) {
        effect = ''
      }  
      line = effect;
  }

  if (Array.isArray(props.consequence)) {
    props.consequence.forEach((element) => {
      let effect = effects[element];
      if (!effect) {
        effect = ''
      }  

      if (line === '') {
        line = effect
      } else {
        line = line + ', ' + effect
      }

    });
  }
}

const maxSymbols = 18;

let trimmed = false;
let label = "";
if (line .length <= maxSymbols) {
  label += line ;
} else {
  trimmed = true;
  label += line .substr(0, maxSymbols) + '…';
}

const tooltip = trimmed ? line  : '';

</script>