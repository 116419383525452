<template>
  <div class="wrapper--white">

    <h1 class="title">Генетические варианты</h1>

    <ARow class="filters mb-24">
      <ACol :xs="24" :md="12" :xl="3">
        <div class="form-group form-group--mr mb-16">
        <label class="mb-12" for="gene-filter">Ген:</label>
        <a-select
          v-model:value="filterList.gene"
          id="gene-filter"
          style="width: 100%"
          placeholder="Все"
          :max-tag-count="2"
          mode="multiple"
          :options= "metadata.genes.map(gene => { return {label: gene, value: gene} })"
          @change="applyFilter"          
        ></a-select>
      </div>
      </ACol>
      <ACol :xs="24" :md="12" :xl="2">
        <div class="form-group form-group--mr mb-16">
        <label class="mb-12" for="gene-filter">Экзон:</label>
        <a-input-number id="inputNumber" v-model:value="filterList.exon" :min="1"  @change="applyFilter"/>
      </div>
      </ACol>
      <ACol :xs="24" :md="12" :xl="5">
        <div class="form-group form-group--mr mb-16">
        <label class="mb-12" for="clinvar-filter">Клиническая значимость по ClinVar:</label>
        <a-select
          v-model:value="filterList.clinvarAnnotation"
          id="clinvar-filter"
          mode="multiple"
          style="width: 100%"
          placeholder="Все"
          :max-tag-count="2"
          :options= "metadata.clinvars.map(clinvar => { return {label: clinvarAnnotations[clinvar], value: clinvar} })"
          @change="applyFilter"
        ></a-select>
      </div>
      </ACol>
      <ACol :xs="24" :md="11" :xl="5">
        <div class="form-group form-group--mr mb-16">
          <label class="mb-12" for="effect-filter">Эффект варината:</label>
          <a-select
            v-model:value="filterList.effect"
            id="effect-filter"
            mode="multiple"
            style="width: 100%"
            placeholder="Все"
            :max-tag-count="2"
            :options= "metadata.effects.map(effect => { return {label: effects[effect], value: effect} })"
             @change="applyFilter"
          ></a-select>
        </div>
      </ACol>
      <ACol :span="1">
        <div class="form-group  mb-16">
          <AButton v-on:click="clearFilter" class="button--icon">
            <ClearOutlined/>
          </AButton>        
        </div>
      </ACol>
    </ARow>

    <ATable
        :columns="columns"
        :row-key="record => record.id"
        :dataSource="dataSource || []"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ x: 592 }"
        @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'position'">
          <RouterLink :to="'/variants/' + record.id">
            {{ record.position }}
          </RouterLink>
        </template>
        <template v-if="column.key === 'varianceDna'">
          <DnaVariance :reference="record.ref" :alt="record.alt"/>
        </template>
        <template v-if="column.key === 'varianceProtein'">
          <ProteinVariance :variance="record.varianceProtein" v-if="record.varianceProtein != null"/>
        </template>
        <template v-if="column.key === 'clinvarAnnotation'">
          {{ clinvarAnnotations[record.clinvarAnnotation] }}
        </template>
        <template v-if="column.key === 'effect'">
          <ImpactPerTranscript :consequence="record.effect"/>                
        </template>
        <template v-if="column.key === 'frequency'">
          <div
              :title="'' + record.somaticSamples + '/' + record.totalSomatic + ' соматических\n' + record.germlineSamples + '/' + record.totalGermline + ' герминальных\n' + record.unknownSamples + '/' + record.totalUnknown + ' неизвестных'">
            {{ record.frequency }}
          </div>
        </template>
      </template>
    </ATable>
  </div>
</template>

<style>
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
  .form-group--mr {
    margin-right: 16px;
  }
  .button--icon {
    min-width: 32px;
    max-width: 32px;
    padding: 0;
    padding-left: 1px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell {
    padding: 12px 16px;
  }
  .ant-input-number {
    width: 100%;
  }
</style>
<script setup>
import {usePagination} from 'vue-request';
import {api, metadata} from "@/http-common";
import {ref, watch} from "vue";
import {clinvarAnnotations, effects} from "@/constants";
import {ClearOutlined} from '@ant-design/icons-vue';
import DnaVariance from "@/components/DnaVariance.vue";
import ProteinVariance from "@/components/ProteinVariance.vue";
import ImpactPerTranscript from "@/components/ImpactPerTranscript.vue";
import {useRoute} from "vue-router";

const route = useRoute();

let filterList = ref({});

const columns = [
  {
    title: 'Ген',
    dataIndex: 'gene',
    key: 'gene',
  }, {
    title: 'Позиция',
    dataIndex: 'position',
    key: 'position',
  }, {
    title: 'Вариант в ДНК',
    dataIndex: 'varianceDna',
    key: 'varianceDna',
  }, {
    title: 'Вариант в белке',
    dataIndex: 'varianceProtein',
    key: 'varianceProtein',
  }, {
    title: 'Экзон',
    dataIndex: 'exon',
    key: 'exon',
  }, {
    title: 'Частота GnomAD3',
    dataIndex: 'frequencyGnomAd',
    key: 'frequencyGnomAd',
  }, {
    title: 'ClinVar аннотация',
    dataIndex: 'clinvarAnnotation',
    key: 'clinvarAnnotation',
  }, {
    title: 'Эффект Варианта',
    dataIndex: 'effect',
    key: 'effect',
  }, {
    title: 'Частота в проекте',
    dataIndex: 'frequency',
    key: 'frequency',
  },
];

const queryData = (params) => {
  let filters = '';
  if (params.gene) {
    for (const gene of params.gene) {
      filters += "&gene=" + gene
    }
  }

  if (params.exon) {
    filters += "&exon=" + params.exon
  }

  if (params.clinvarAnnotation) {
    for (const clinvar of params.clinvarAnnotation) {
      filters += "&clinvar=" + clinvar
    }
  }

  if (params.effect) {
    for (const effect of params.effect) {
      filters += "&effect=" + effect
    }
  }

  if (route.query.q) {
    filters += "&q=" + route.query.q
  }

  return api.get("/variants?page=" + params.current + '&pageSize=' + params.pageSize + filters).then((response) => {
    pagination.value.total = response.data.total;
    pagination.value.current = response.data.current;
    pagination.value.pageSize = response.data.pageSize;
    return response.data.data;
  });
}

const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
  refresh
} = usePagination(queryData, {
  pagination: {
    currentKey: 'current',
    pageSizeKey: 'pageSize',
    totalKey: 'total',
    totalPageKey: 'totalPage',
  },
});

const pagination = ref({
  total: 0,
  current: current.value,
  pageSize: pageSize.value,
  showTotal: total => `Всего ${total} генетических вариантов`,

});

const handleTableChange = (
    page,
    filters,
    sorter,
) => {
  filters = filterList.value;
  run({    
    pageSize: page.pageSize,
    current: page?.current,
    sortField: sorter.field,
    sortOrder: sorter.order,
    ...filters,
  });
};

const clearFilter = () => {
  filterList.value = {};
  const page = pagination.value;
  page.current = 1;
  loading.value = true;
  handleTableChange(page, filterList.value, {});
};

//TODO возможно работу с фильтрами можно оптимизировать
const applyFilter = () => {
  const page = pagination.value;
  page.current = 1;
  loading.value = true;
  handleTableChange(page, filterList.value, {});
};

watch(
    () => route.query,
    async () => {
      current.value = 1;
      refresh();
    }
);
</script>
