<template>
  <div v-if="variant"  class="mb-24">
    <ASpace wrap style="margin-top: 10px; margin-bottom: 0">
      <AButton v-on:click="backHandler" style="margin-left: 10px; padding-left: 10px; padding-right: 10px">
        <ArrowLeftOutlined/>
      </AButton>
      <ASpace wrap class="detail-title">
        <div class="detail-item">
          Образцы связанные с вариантом
        </div>
        <div class="detail-item detail-item--mr">{{ variant.position}}</div>
        <div class="detail-item detail-item--mr"><strong>{{ mainTranscriptHGVSc }}</strong></div>
        <div class="detail-item detail-item--mr ">
          <div class="detail-item__label">
              Ген:
          </div>
          <div class="detail-item__value">
            <strong>{{ variant.annotation.geneSymbol }}</strong>
          </div>
        </div>
        <div v-if="variant.annotation.exon" class="detail-item detail-item--mr ">
          <div class="detail-item__label">
            Экзон: 
          </div>
          <div class="detail-item__value">
            <strong>{{ variant.annotation.exon }}</strong>
          </div>
        </div>
        <div v-if="variant.frequency" class="detail-item detail-item--mr ">
          <div class="detail-item__label">
            Частота в проекте: 
          </div>
          <div class="detail-item__value">
            <strong>{{ variant.frequency }}</strong>
          </div>
        </div>
      </ASpace>   
    </ASpace>
  </div>
  <div class="wrapper--white">
    <h1 class="title" v-if="!variant">Образцы</h1>
    <ATable
        :columns="columns"
        :row-key="record => record.id"
        :dataSource="dataSource || []"
        :loading="loading"
        :pagination="pagination"
        :custom-row="customRow"
        :scroll="{ x: 592 }"
        @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'laboratoryName'">
          <a @click="info(record.laboratoryId)">{{ record.laboratoryName }}</a>
        </template>
      </template>
    </ATable>
  </div>

  <AModal v-model:open="open" width="1000px" title="Инфонмация о лаборатории" @ok="handleOk">
    <LaboratoryCard :lab="lab"/>
    <template #footer>
      <AButton type="primary" @click="handleOk">Закрыть</Abutton>
      </template>
  </AModal>

</template>

<script setup>
import {usePagination} from 'vue-request';
import {api} from "@/http-common";
import {ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {ArrowLeftOutlined} from '@ant-design/icons-vue';
import LaboratoryCard from "@/components/LaboratoryCard.vue";

const route = useRoute();
const router = useRouter();

const variantId = route.params.id;
let variant = null;
let mainTranscriptHGVSc = '';

const columns = [
  {
    title: 'Образец',
    dataIndex: 'patientId',
    key: 'patientId',
  }, {
    title: 'Диагноз',
    dataIndex: 'diagnosis',
    key: 'diagnosis',
  }, {
    title: 'Лаборатория',
    dataIndex: 'laboratoryName',

    key: 'laboratoryName',
  }, {
    title: 'Комментарий',
    dataIndex: 'notes',
    key: 'notes',
  }
];

const open = ref(false);
const lab = ref({});

const handleOk = () => {
  open.value = false;
};

const queryData = (params) => {
  let url = "/samples?page=" + params.current + '&pageSize=' + params.pageSize;

  if (variantId) {
    url += '&variantId=' + variantId;
  }

  return api.get(url).then((response) => {
    pagination.value.total = response.data.total;
    pagination.value.current = response.data.current;
    pagination.value.pageSize = response.data.pageSize;
    return response.data.data;
  });
}


if (variantId) {
  variant = await api.get("/variants/" + route.params.id).then(res => res.data)

  for (let i = 0; i < variant.annotation.transcripts.length; i++) {
    let transcript = variant.annotation.transcripts[i];
    if (transcript.main) {
      mainTranscriptHGVSc = transcript.hgvsc;
    }
  }

}

const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
  refresh,
} = usePagination(queryData, {
  pagination: {
    currentKey: 'current',
    pageSizeKey: 'pageSize',
    totalKey: 'total',
    totalPageKey: 'totalPage',
  },
});

const pagination = ref({
  total: 0,
  current: current.value,
  pageSize: pageSize.value,
  showTotal: total => `Всего ${total} образцов`,
  size: 'test'
});

const handleTableChange = (
    page,
    filters,
    sorter,
) => {
  run({
    pageSize: page.pageSize,
    current: page?.current,
    sortField: sorter.field,
    sortOrder: sorter.order,
    ...filters,
  });
};

const backHandler = () => router.push("/variants/" + variantId)

const info = async (laboratoryId) => {
  const laboratory = await api.get("/laboratories/" + laboratoryId).then(res => res.data);
  lab.value = laboratory || {};
  open.value = true;
};

watch(
    () => route.query,
    async () => {
      current.value = 1;
      refresh();
    }
);
</script>