<template>
  <div class="wrapper--white">

    <h1 class="title mb-32">Контакты</h1>
    <h2 class="mb-24">Организаторы</h2>

    <ACard title="Национальное общество онко-пульмонологов" style="margin-bottom: 24px;">
      <div>Телефон: +7 915 149 6783</div>
      <div>Электронная почта: <a href="mailto:info@oncopulm.ru">info@oncopulm.ru</a></div>
    </ACard>   

    <h2 class="mb-24">Лаборатории</h2>

    <LaboratoryCard v-for="lab in laboratories" :key="lab.id" :lab="lab"/>

  </div>
</template>
<script setup>
  import LaboratoryCard from "@/components/LaboratoryCard.vue";
  import {api} from "@/http-common";

  const laboratories = await api.get("/laboratories").then(res => res.data);
  
</script>