import {createApp} from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import {createWebHistory, createRouter} from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import VariantsPage from "@/pages/VariantsPage.vue";
import VariantDetailsPage from "@/pages/VariantDetailsPage.vue";
import SamplesPage from "@/pages/SamplesPage.vue";
import ContactsPage from "@/pages/ContactsPage.vue";

const routes = [{
    path: '/',
    component: HomePage,
    name: 'home',
}, {
    path: '/variants',
    component: VariantsPage,
    name: 'variants',
}, {
    path: '/variants/:id',
    component: VariantDetailsPage,
    name: 'variantDetails',
}, {
    path: '/samples',
    component: SamplesPage,
    name: 'samples',
}, {
    path: '/samples/:id',
    component: SamplesPage,
    name: 'samplesByVariant',
}, {
    path: '/contacts',
    component: ContactsPage,
    name: 'contacts',
}]

const router = createRouter({
    mode: 'history',
    routes: routes,
    history: createWebHistory()
});

const app = createApp(App);
app.use(Antd);
app.use(router);

router.isReady().then(() => app.mount('#app'))
