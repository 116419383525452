<template>
  <div :title="tooltip" style="white-space: nowrap">
    <span :style="main ? 'font-weight: bold' : ''">{{ label }}</span>
    <CopyOutlined v-if="trimmed" @click="clicked"/>
  </div>

</template>
<script setup>
import {toRefs} from "vue";
import {CopyOutlined} from '@ant-design/icons-vue';

const props = defineProps(['variance', 'main'])

const {variance} = toRefs(props);
const {main} = toRefs(props);

let proteinVar = variance.value ? variance.value : ''

const maxSymbols = 8;

let trimmed = false;
let label = "";
if (proteinVar.length <= maxSymbols) {
  label += proteinVar;
} else {
  trimmed = true;
  label += proteinVar.substr(0, maxSymbols) + '…';
}

const tooltip = trimmed ? proteinVar : '';

const clicked = async () => navigator.clipboard.writeText(proteinVar)

</script>