<template>
  <ARow>
    <ACol :xs="24" :lg="16">
      <ASpace wrap style="margin-top: 10px; margin-bottom: 0">
        <AButton v-on:click="backHandler" style="margin-left: 10px; padding-left: 10px; padding-right: 10px">
          <ArrowLeftOutlined/>
        </AButton>
        <ASpace wrap class="detail-title">
          <div class="detail-item detail-item--mr">{{ variant.position}}</div>
          <div class="detail-item detail-item--mr"><strong>{{ mainTranscriptHGVSc }}</strong></div>

          <div class="detail-item detail-item--mr ">
            <div class="detail-item__label">
                Ген:
            </div>
            <div class="detail-item__value">
              <strong>{{ variant.annotation.geneSymbol }}</strong>
            </div>
          </div>
          <div v-if="variant.annotation.exon" class="detail-item detail-item--mr ">
            <div class="detail-item__label">
              Экзон: 
            </div>
            <div class="detail-item__value">
              <strong>{{ variant.annotation.exon }}</strong>
            </div>
          </div>
          <div v-if="variant.frequency" class="detail-item detail-item--mr " :title="'' + variant.somaticSamples + '/' + variant.totalSomatic + ' соматических\n' + variant.germlineSamples + '/' + variant.totalGermline + ' герминальных\n' + variant.unknownSamples + '/' + variant.totalUnknown + ' неизвестных'">
            <div class="detail-item__label">
              Частота в проекте: 
            </div>
            <div class="detail-item__value">
              <strong>{{ variant.frequency }}</strong>
            </div>
          </div>
        </ASpace>           
      </ASpace>   
    </ACol>
    <ACol :xs="24" :lg="8" style="text-align: right;">
      <ASpace wrap style="margin-right: 10px; margin-top: 10px">
        <AButton type="default" v-if="variant.annotation.clinvarId" style="padding-left: 10px; padding-right: 10px">
          <a target="_blank" :href="'https://preview.ncbi.nlm.nih.gov/clinvar/variation/' + variant.annotation.clinvarId">ClinVar</a>
        </AButton>

        <AButton type="default" style="padding-left: 10px; padding-right: 10px">
          <a target="_blank" :href="'https://varsome.com/position/hg38/' + variant.position" >VarSome</a>
        </AButton>

        <AButton type="primary" style="padding-left: 10px; padding-right: 10px">
          <RouterLink :to="'/samples/' + variant.id">Связанные образцы</RouterLink>
        </AButton>
      </ASpace>
    </ACol>
  </ARow> 
  <ARow>
    <ACol :xs="24" :xl="12" style="margin-bottom: 14px">
      <ACard title="Транскрипты" class="transcripts-card">
        <ATabs v-model:activeKey="transcriptsKey">
          <ATabPane key="ensembl" tab="Ensembl">
            <ATable
              :dataSource="variant.annotation.transcripts"
              :columns="transcriptsColumns"
              :pagination="false"
              :scroll="{x: 500}"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'consequence'">
                  <ImpactPerTranscript :consequence="record.consequence" :main="record.main"/>                
                </template>
                <template v-if="column.key === 'id'">
                  <span :style="record.main ? 'font-weight: bold' : ''">{{ record.id }}</span>
                </template>
                <template v-if="column.key === 'hgvsp'">
                  <ProteinVariance :variance="record.hgvsp" :main="record.main"/>
                </template>
                <template v-if="column.key === 'hgvsc'">
                  <HgvscVariance :variance="record.hgvsc" :main="record.main"/>
                </template>
              </template>
            </ATable>
          </ATabPane>
          <ATabPane key="refseq" tab="RefSeq">
            <ATable
              :dataSource="variant.annotation.refSeqTranscripts"
              :columns="transcriptsColumns"
              :pagination="false"
              :scroll="{x: 500}"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'consequence'">
                  <ImpactPerTranscript :consequence="record.consequence" :main="record.main"/>                
                </template>
                <template v-if="column.key === 'id'">
                  <span :style="record.main ? 'font-weight: bold' : ''">{{ record.id }}</span>
                </template>
                <template v-if="column.key === 'hgvsp'">
                  <ProteinVariance :variance="record.hgvsp" :main="record.main"/>
                </template>
                <template v-if="column.key === 'hgvsc'">
                  <HgvscVariance :variance="record.hgvsc" :main="record.main"/>
                </template>
              </template>
            </ATable>

          </ATabPane>
        </ATabs>
      </ACard>
    </ACol>
    <ACol :xs="24" :xl="12">
      <ACard title="ClinVar">
        <ATable
            v-if="variant.annotation.clinvarPheotypes.length > 0"
            :dataSource="variant.annotation.clinvarPheotypes"
            :columns="clinvarColumns"
            :pagination="false"
            :scroll="{x: 500}"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'phenotype'">
              {{ record.phenotype.replace("_", " ") }}
            </template>
            <template v-if="column.key === 'tumorType'">
              {{ clinvarAnnotations[record.tumorType] }}
            </template>
          </template>
        </ATable>
        <AEmpty v-if="variant.annotation.clinvarPheotypes.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
  <ARow style="margin-top: 14px;">
    <ACol :span="24">
      <ACard title="GnomAD3">
        <AFlex gap="large" wrap="wrap" v-if="variant.annotation.gnomad3AlleleFrequency.length > 0">
          <div v-for="item in variant.annotation.gnomad3AlleleFrequency" :key="item.name" style="white-space: nowrap">
            <div style="width: 300px; text-align: right">
              {{ item.name }} - {{ item.frequency }}
            </div>
          </div>
        </AFlex>
        <AEmpty v-if="variant.annotation.gnomad3AlleleFrequency.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
  <ARow style="margin-top: 14px;">
    <ACol :span="24">
      <ACard title="ExAC">
        <AFlex gap="large" wrap="wrap" v-if="variant.annotation.exacAlleleFrequency.length > 0">
          <div v-for="item in variant.annotation.exacAlleleFrequency" :key="item.name" style="white-space: nowrap">
            <div style="width: 300px; text-align: right">
              {{ item.name }} - {{ item.frequency }}
            </div>
          </div>
        </AFlex>
        <AEmpty v-if="variant.annotation.exacAlleleFrequency.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
</template>
<style>
  .ant-card {
    margin: 5px;
  }

  .ant-card.transcripts-card .ant-card-body {
    padding-top: 0px;
  } 

  .ant-row {
    padding: 5px;
  }

  .ant-card-head-title {
    padding: 15px;
  }
</style>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {ArrowLeftOutlined} from '@ant-design/icons-vue';
import {api} from "@/http-common";
import {clinvarAnnotations} from "@/constants";
import ProteinVariance from "@/components/ProteinVariance.vue";
import HgvscVariance from "@/components/HgvscVariance.vue";
import ImpactPerTranscript from "@/components/ImpactPerTranscript.vue";

import {Empty} from "ant-design-vue";
import {ref} from "vue";

const route = useRoute();
const router = useRouter();

const variant = await api.get("/variants/" + route.params.id).then(res => res.data)

const transcriptsColumns = [
  {
    title: 'Транскрипт',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: 'Влияние',
    dataIndex: 'consequence',
    key: 'consequence',
  }, {
    title: 'HGVSc',
    dataIndex: 'hgvsc',
    key: 'hgvsc',
  }, {
    title: 'HGVSp',
    dataIndex: 'hgvsp',
    key: 'hgvsp',
  }
]

const clinvarColumns = [
  {
    title: 'Фенотип',
    dataIndex: 'phenotype',
    key: 'phenotype',
  }, {
    title: 'Клиническая значимость',
    dataIndex: 'tumorType',
    key: 'tumorType',
  }, {
    title: 'Статус проверки',
    dataIndex: 'metadata',
    key: 'metadata',
  }
]

const backHandler = () => router.push("/variants/")

let mainTranscriptHGVSc = '';

for (let i = 0; i < variant.annotation.transcripts.length; i++) {
  let transcript = variant.annotation.transcripts[i];
  if (transcript.main) {
    mainTranscriptHGVSc = transcript.hgvsc;
  }
}

const transcriptsKey = ref('ensembl');

</script>