<template>
    <a :id="lab.laboratoryId"/>
    <ACard style="margin-bottom: 24px;" :title="lab.laboratoryName" class="laboratory mb-24">
      <div v-if="lab.contacts">Контактное лицо: {{ lab.contacts }}</div>
      <div v-if="lab.phone">Телефон: {{ lab.phone }}</div>
      <div v-if="lab.email">Электронная почта: <a :href="'mailto:' + lab.email">{{ lab.email }}</a></div>
    </ACard>  
</template>
<style>
  .ant-card.laboratory .ant-card-head-title {
    white-space: normal;
    text-overflow: clip;
  }
</style>
<script setup>
  import {toRefs} from "vue";
  
  const props = defineProps(['lab']);
  const {lab} = toRefs(props);
   
</script>