<template>
    <div class="wrapper--white">
        <div class="bunner mb-24" :style="{ backgroundImage: 'url(' + './assets/images/background.png' + ')' }">
            <div class="search">
                <AInputSearch
                    v-model:value="query"
                    placeholder="Например: “c.2156G>C”, “EGFR” или “p.Gly12Asp”"
                    size="large"
                    enter-button="Поиск"
                    @search="onSearch"
                    style="width: 100%; padding-top: 10px"
                />
            </div>        
        </div>
        <div class="content">
            <h1 class="title title--center mb-32">
                О проекте OncoLung
            </h1>
            <ATypography>
                <ATypographyParagraph>
                    OncoLung — это современная база данных, которая аккумулирует информацию о генетических вариантах, ассоциированных с немелкоклеточным раком лёгкого (НМРЛ). База данных построена на более чем 1000 образцах, проанализированных с помощью NGS (массовое параллельное секвенирование), которые поступили из трёх лабораторий на территории РФ. Генетические данные получены в рамках проектов, курируемых Национальным обществом онкопульмонологов (НООП), и регулярно пополняются новыми результатами.
                </ATypographyParagraph>
                <ATypographyParagraph>
                    Каждый генетический вариант в OncoLung проходит тщательную аннотацию, включая функциональную характеристику, частотные данные и клиническую значимость. Целью проекта является составление спектра генетических изменений при НМРЛ для поддержки интерпретации данных NGS и помощи специалистам в понимании ключевых мутаций, влияющих на развитие и прогрессию рака лёгкого.             
                </ATypographyParagraph>
                <ATypographyParagraph>
                    OncoLung — это профессиональный инструмент, разработанный для онкологов и врачей-генетиков. Вся представленная информация предназначена исключительно для профессионального использования.
                </ATypographyParagraph>
            </ATypography> 
        </div>  
    </div>
  </template>
  <style>
    .bunner {
        height: 240px;
        
        border-radius: 8px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }    
    .search {
        width: 100%;
        max-width: 640px;
        height: 40px;
    }
    @media screen and (max-width: 768px) { 
        .bunner {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
  </style>
  <script setup>
    import {useRoute, useRouter} from "vue-router";
    import {ref} from "vue";

    const route = useRoute();
    const router = useRouter();

    const query = ref(route.query.q)

    const onSearch = (query) => {
        router.push({ path: "/variants", query: {q: query}})
        return true
    }

  </script>